export enum FavoriteType {
  Floorplan = 'Floor Plans',
  Elevation = 'Exteriors',
  Media = 'Interiors',
  Lot = 'Lots',
  LotMedia = 'Media',
  PointOfInterest = 'Points of Interest',
  InventoryHome = 'Inventory Homes',
  PageContentSection = 'Items',
  VisualizerSelection = 'Visualizer Selections',
  AmenityMedia = 'Amenity Images'
}
